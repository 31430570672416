import { FormCheckbox, IUseFormReturn } from 'helpers/form';
import React from 'react';
import { useSessionStore } from 'store/session-store';
import { ILgtContact } from 'types';

export const CustomContactRowEdit: React.FC<Partial<IUseFormReturn<ILgtContact>>> = () => <></>;

export const CustomContactSettingsRowEdit: React.FC<Partial<IUseFormReturn<ILgtContact>>> = ({
  formData,
  handlers,
}) => {
  const user = useSessionStore((state) => state.user);
  const disabledField = !['Super Admin', 'Admin'].includes(user?.role_name as string);

  return (
    <FormCheckbox
      name="disable_watermark"
      label="Disable Watermark"
      {...handlers}
      {...formData?.disable_watermark}
      disabled={disabledField}
    />
  );
};
