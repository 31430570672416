import { Pages } from 'utils/actions/types';
import { IFeatureFlag, ISubFeatureFlag } from 'types';

const featureFlags: IFeatureFlag = {
  isMm3Assets: false,
  showCastCrewFeature: true,
  showBasketsFeature: true,
  showCollectionsFeature: false,
  showScreeningRoomFeature: true,
  showEventFeature: false,
  showConferenceFeature: true,
  showRecommendationFeature: true,
  showAssetRecommendationsFeature: false,
  showAccessPrivilegeFeature: false,
  showMobileSelectionsFeature: true,
  showMeetingsFeature: true,
  showProducerPortalFeature: false,
  showDeliveryFeature: false,
  showMediaBenchFeature: false,
  showAccessGroupFeature: false,
  showGuildsFeature: false,
  showSmartGroupFeature: false,
  showOrderManagementFeature: false,
  showWebsiteFeature: true,
  showAdvertisementsFeature: false,
};

const subFeatureFlags: ISubFeatureFlag = {
  showNewsManageShowcaseAction: false,
  showEventLayout: false,
  showAutonomousShowcaseButtons: false,
  showCollectionImagesTab: false,
  showFutureEpisodesFeature: false,
  showListRecommendationOnWebsiteFeature: false,
  showRelatedProductsFeature: true,
  hasCustomClassifications: false,
  showSubtitleGenerationFeature: false,
};

export const flags = {
  ssoProvider: 'okta',
  ...featureFlags,
  ...subFeatureFlags,
};

export const mbImportAllowedRoles = [];

export const allowedRolesByRoute = {};

export const getShowPermissions = (page?: Pages): boolean => Boolean(page || true);
