import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';

import { AssetsMainClassificationLink } from 'components/asset/assets-aggregation/product-assets';
import { IProduct, IProductAncestryInfo, IProductAsset, DetailsPageTabs } from 'types';
import { formatDate } from 'utils/date';
import { formatField, formatFullName } from 'utils/general';
import { DivisionsInfo } from 'utils/ui';

import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { ProductOverviewAbout } from 'components/product/overview';
import { ProductAssetsPreview } from './product-overview-assets-preview';

import './style.scss';

export interface IProductOverviewTabProps {
  product?: IProduct;
  assets?: IProductAsset[];
  ancestry?: IProductAncestryInfo;
}

const ProductOverviewTab: React.FC<IProductOverviewTabProps> = observer(({ product, assets, ancestry }) => {
  const {
    access_level,
    default_layer,
    status,
    created_at,
    updated_at,
    published_at,
    id,
    permissions,
    division_ids,
    owner,
    owner_organization,
    available_since,
  } = product || {};

  return (
    <div className="product-general-tab h-100">
      <div className="product-general-tab__wrapper h-100">
        <div className="product-general-tab__info">
          {default_layer?.synopsis && (
            <div className="product-general-tab__synopsis">
              <div
                className="product-general-tab__synopsis-text mb-1"
                dangerouslySetInnerHTML={{ __html: default_layer.synopsis }}
              />
              <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.METADATA}`}>Read all</Link>
            </div>
          )}

          <div className="product-general-tab__account-info">
            <InfoBoxV2 gridTemplateColumns="1fr 1fr" title="SETTINGS" wrapperClassName="mt-3" className="mt-3">
              <Row
                label="Assets"
                value={
                  <AssetsMainClassificationLink
                    assets={assets}
                    muted={false}
                    baseUrl={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.ASSETS}`}
                  />
                }
              />
              <Row
                label="Access"
                value={
                  <>
                    {access_level} <ScheduledChangeIndicator entity={product} />
                  </>
                }
              />
              <Row label="Status" value={status} />
              <Row label="Permissions" hideIfNoValue={Boolean(permissions)} value={formatField(permissions)} />
            </InfoBoxV2>
            <InfoBoxV2 gridTemplateColumns="1fr 1fr" title="Ownership" wrapperClassName="mt-3">
              <Row label="Owner" value={formatFullName(owner)} />
              <Row label="Divisions" value={<DivisionsInfo division_ids={division_ids} />} size="m" />
              <Row label="Organization" value={owner_organization?.name} />
            </InfoBoxV2>
            <InfoBoxV2 gridTemplateColumns="1fr 1fr" title="Timestamps" wrapperClassName="mt-3">
              <Row label="Created at" rawLabel value={formatDate(created_at)} />
              <Row label="Updated at" rawLabel value={formatDate(updated_at)} />
              <Row label="Published at" rawLabel value={formatDate(published_at)} />
              <Row label="Available since" rawLabel value={formatDate(available_since)} />
            </InfoBoxV2>
            <ProductOverviewAbout product={product} ancestry={ancestry} wrapperClassName="mt-3 mb-3" />
          </div>
        </div>

        <ProductAssetsPreview assets={assets} />
      </div>
    </div>
  );
});

export default ProductOverviewTab;
