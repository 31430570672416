import { CollectionsPage } from 'pages/collections';
import { NewsPage } from 'pages/news';
import { Routes } from 'utils/routes';
import { IWebsitePageValues } from 'types/list';

export const SIDE_BAR_WEBSITE_SECTION: {
  route: Routes;
  text: string;
  section?: IWebsitePageValues;
  component?: React.FC<{ section?: IWebsitePageValues; title?: string }>;
}[] = [
  {
    route: Routes.WEBSITES_HOME_PAGE,
    text: 'Home Page',
    section: IWebsitePageValues.HOME,
  },
  {
    route: Routes.WEBSITES_GENRES,
    text: 'Genre Pages',
    section: IWebsitePageValues.GENRES,
  },
  {
    route: Routes.WEBSITES_COLLECTIONS,
    text: 'Collection Pages',
    section: IWebsitePageValues.COLLECTION_PAGES,
  },
  {
    route: Routes.WEBSITES_CONTACT,
    text: 'Contacts Page',
    section: IWebsitePageValues.CONTACTS,
  },
  {
    route: Routes.WEBSITES_REGISTRATION_PAGE,
    text: 'Registration Page',
    section: IWebsitePageValues.REGISTRATION,
  },
  {
    route: Routes.WEBSITES_NEWS,
    text: 'News Page',
    section: IWebsitePageValues.NEWS,
  },
  {
    route: Routes.WEBSITES_DOWNLOADS,
    text: 'Downloads Page',
    section: IWebsitePageValues.DOWNLOADS,
  },
  {
    route: Routes.WEBSITES_MICROSITES,
    text: 'Micro Sites',
    section: IWebsitePageValues.MICROSITES,
  },
  {
    route: Routes.ADVERTISEMENTS,
    text: 'Advertisements',
    section: IWebsitePageValues.ADVERTISEMENTS,
  },
  {
    route: Routes.COLLECTIONS,
    text: 'Collections',
    section: IWebsitePageValues.COLLECTIONS,
    component: CollectionsPage,
  },
  {
    route: Routes.NEWS,
    text: 'News',
    component: NewsPage,
  },
];
